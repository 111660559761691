import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import "./StripeForm.scss";
import React, {useEffect, useState} from "react";
import logo from "../../assets/svg/Logo";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// for testing: pk_test_51M5SqaGUx03Blwd2KppapqdFd0CCFdxzyLDBGyELgUXWVIqQXsuzWbtYy2udiSBuYUnuNucV1a1r7JuszsqnFdiS00bgESRk3M
// for live: pk_live_51M5SqaGUx03Blwd2uO1T0T6GpmpfdKFR4s63oCijmDb3OODJbtfMCHfFpbJIfbaAHShw6QhwiNTLudf4FK7lAVqP00RgmlTzqA
const stripePromise = loadStripe("pk_live_51M5SqaGUx03Blwd2uO1T0T6GpmpfdKFR4s63oCijmDb3OODJbtfMCHfFpbJIfbaAHShw6QhwiNTLudf4FK7lAVqP00RgmlTzqA", {
    locale: 'ro'
});

export default function StripeForm() {
    const [clientSecret, setClientSecret] = useState("");
    const [amount, setAmount] = useState(15);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        // Create PaymentIntent as soon as the page loads
        fetch("https://centrulgosen.com/api/stripeConnection", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                items: [
                    {id: "xl-tshirt", amount: amount}
                ]
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (clientSecret === "") {
                    setClientSecret(data.clientSecret);
                    setLoading(false);
                }
            })
            .catch((error) => {
                // Handle error if the request fails
                console.log(error);
                setLoading(false);  // Ensure loading is turned off even on error
            });
    }, []);

    // useEffect(() => {
    //     if (amount) {
    //         fetch("https://centrulgosen.com/api/stripeUpdate", {
    //             method: "POST",
    //             headers: {"Content-Type": "application/json"},
    //             body: JSON.stringify({
    //                 items: [
    //                     {id: clientSecret, amount: amount}
    //                 ]
    //             }),
    //         })
    //             .then((res) => res.json())
    //             .then((data) => {
    //                 if (clientSecret === "") {
    //                     setClientSecret(data.clientSecret)
    //                 }
    //             });
    //     }
    //
    // }, [amount]);

    useEffect(() => {
        if (amount) {
            fetch("https://centrulgosen.com/api/stripeUpdate", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    items: [
                        {id: clientSecret, amount: amount}
                    ]
                }),
            })
                .then((res) => {
                    if (!res.ok) {
                        // If response status is not 2xx, throw an error
                        throw new Error(`Network response was not ok, status: ${res.status}`);
                    }

                    // Log raw response to check if it is empty or non-JSON
                    return res.text(); // Read the response body as text first
                })
                .then((text) => {
                    if (!text) {
                        // If the response body is empty, log a message
                        console.error("Received empty response from server");
                        return;
                    }

                    // Try parsing the response as JSON
                    try {
                        const data = JSON.parse(text); // Parse the response text into JSON
                        if (data && data.clientSecret) {
                            if (clientSecret === "") {
                                setClientSecret(data.clientSecret);
                            }
                        } else {
                            console.error("Client secret is not available in the response", data);
                        }
                    } catch (error) {
                        // If JSON parsing fails, log the error
                        console.error("Error parsing JSON response", error);
                    }
                })
                .catch((error) => {
                    // Catch any errors that occur during fetch or JSON parsing
                    console.log('Fetch error:', error);
                });
        }
    }, [amount]);


    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className="StripeFromContainer">
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm amount={amount} setAmount={setAmount}/>
                </Elements>
            )}
        </div>
    );
};