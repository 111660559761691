import React, {useEffect, useState} from "react";
import "./ListaCampanii.scss";
import Campaign from "./Campanie/Campaign";

// interface State {
//
// }

export default function ListaCampanii(props) {
    const [campaigns, setCampaigns] = useState([]);

    // Fetch campaigns from the API
    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await fetch("https://www.centrulgosen.com/api/campaigns");
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setCampaigns(data);
            } catch (error) {
                console.error("Error fetching campaigns:", error);
            }
        };

        fetchCampaigns();
    }, []);

    // Helper function to calculate remaining days
    const calculateDaysLeft = (details) => {
        const currentDate = new Date();
        let endDate;
    
        const donationType = details.find((item) => item.donationType)?.donationType;
        const finalDate = details.find((item) => item.finalDate)?.finalDate;
    
        if (donationType === "monthly") {
            // End of the current month
            endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        } else if (donationType === "yearly") {
            // End of the current year
            endDate = new Date(currentDate.getFullYear(), 11, 31);
        } else if (donationType === "once" && finalDate) {
            // Use finalDate from details
            endDate = new Date(finalDate);
        } else {
            return 0; // Default case: No days left
        }
    
        // Calculate days left
        const timeDifference = endDate - currentDate;
        return Math.max(0, Math.ceil(timeDifference / (1000 * 60 * 60 * 24))); // Convert to days
    };

    return (
        <div id={"ListaCampanii"} style={{ paddingTop: "80px", minHeight: "calc(100vh - 105px)" }}>
            <div className={"container py-5"}>
                <div className={"row py-5"}>
                    <div className={"col-xs-12"}>
                        <h1 className={"text-center"}>Campanii organizate de Centru Gosen</h1>
                    </div>
                </div>
                {campaigns.length > 0 ? (
                    campaigns.map((campaign) => {
                        const details = JSON.parse(campaign.details); // Parse details JSON
                        const daysLeft = calculateDaysLeft(details);
                
                        const donationType = details.find((item) => item.donationType)?.donationType;
                        const goal = campaign.donation_goal
                
                        return (
                            <Campaign
                                key={campaign.id}
                                title={campaign.title}
                                description={campaign.description}
                                moneyDonated={parseFloat(campaign.current_donated)}
                                moneyNeeded={goal}
                                daysLeft={daysLeft}
                                image={campaign.image} // Use the image column
                            />
                        );
                    })
                ) : (
                    <p className="text-center">Se încarcă campaniile...</p>
                )}
            </div>
        </div>
    );
}


