import React from "react";
import "./Doneaza.scss";
// Sections
import bgheader from "../assets/img/pages/donation/joel_overbeck.jpg";
import CheckMark from "../assets/svg/Checkmark";
import {Button} from "@mui/material";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import StripeForm from "../components/StripeForm/StripeForm";
import FormattedInput from "../components/FormattedInput/FormattedInput";
import SelectCurrency from "../components/SelectCurrency/SelectCurrency";
import {NavLink} from "react-router-dom";

export default function Doneaza() {
    return (
        <div id="home" className={'doneazaContent'} style={{paddingTop: "80px"}}>
            <div className={'despreAutismHeader'} style={{backgroundImage: `url("${bgheader}")`}}></div>
            <div className="container">
                <h1 className="font40 extraBold" style={{marginBottom: "64px"}} >Poți dărui pentru activitățile Centrului Gosen prin una dintre următoarele metode</h1>

                <h4 className="font30 extraBold">1.Donează online folosind cardul</h4>
                <p className="font16 semiBold" style={{marginBottom: "16px"}}>
                    Donează online folosind cardul!
                </p>

                {/*<NavLink to={"/doneaza-online"}>*/}
                    <a href="https://donate.stripe.com/aEUfZe5dJb6z1H27ss">
                    <Button style={{
                        marginBottom: "64px",
                        backgroundColor: "#FF0000",
                        padding: "12px 26px",
                        fontSize: "16px",
                    }} startIcon={<CreditCardIcon />} color={'warning'} variant="contained" size="large"
                            type={"default"}>
                        Donează acum!
                    </Button>
                    </a>
                {/*</NavLink>*/}
                <h4 className="font30 extraBold">2.Contul IBAN</h4>
                <p className="font16 semiBold" style={{marginBottom: "16px"}}>
                    Banca Comercială Română
                </p>
                <p className="font16 semiBold" style={{marginBottom: "48px", textTransform: "uppercase"}}>
                    Asociația Creștină de Caritate Gosen Arad
                </p>
                <p className="font16 semiBold">
                    RON: RO62RNCB0015030318770001
                </p>
                <p className="font16 semiBold">
                    EUR: RO43RNCB0016030318770002
                </p>
                <p className="font16 semiBold" style={{marginBottom: "48px"}}>
                    DOLARI: RO16RNCB0016030318770003
                </p>

                <p className="font16 semiBold">
                    COD SWIFT: RNCBROBUXXX
                </p>
                <p className="font16 semiBold" style={{marginBottom: "64px"}}>
                    CUI - 9170165
                </p>

                <h4 className="font30 extraBold">3.Redirecționează 3,5% din impozitul pe venit</h4>
                <p className="font16" style={{marginBottom: "32px"}}>
                    Acum poți face acest lucru direct online accesând butonul de mai jos (sau accesând link-ul <a target={"_blank"} href={"https://redirectioneaza.ro/centrulgosen"}>redirectioneaza.ro/centrulgosen</a>):
                </p>
                <a href={"https://redirectioneaza.ro/centrulgosen"} target="_blank">
                    <Button style={{marginBottom: "64px"}} variant="contained"  color="primary" size="large">
                        Redirectionează 3,5%
                    </Button>
                </a>

                <h4 className="font30 extraBold">4.Redirecționează 20% din impozitul anual pe profit/venit</h4>
                <p className="font16 semiBold" style={{marginBottom: "32px"}}>
                    Conform Codului Fiscal, dacă ai o societate comercială sau o microîntreprindere, poți direcționa 20% din impozitul pe profit sau venit datorat statului, către Asociația Creștină de Caritate Gosen Arad.
                </p>

                <a href={"https://docs.google.com/document/d/131oXArmt6P6yBNHhc4x8301WYLKoC4YC/edit?usp=share_link&ouid=101445355343345699833&rtpof=true&sd=true\n"} target="_blank">
                    <Button style={{marginBottom: "64px"}} variant="contained"  color="primary" size="large">
                        Contract de Sponsorizare
                    </Button>
                </a>

                <ul>
                    <li>
                        <CheckMark /> contactează-ne prin email <a href={"mailto:asociatiagosen@gmail.com"}>asociatiagosen@gmail.com</a> sau telefonic <a href={"tel:0756930650"}>0756 930 650</a> pentru detalii.
                    </li>
                </ul>

            </div>
        </div>
    );
}


