import React, { useEffect, useState } from "react";
import "./Campaign.scss";

import LinearProgressBar from "../../../components/LinearProgress/LinearProgress";
import Button from "@mui/material/Button";

export default function Campaign(props) {
    const { title, description, moneyDonated, moneyNeeded, daysLeft, image } = props;

    const percentCompleted = (moneyDonated / moneyNeeded) * 100;

    return (
        <div className={"row-campaign row py-3 mb-5"}>
            <div className={"col-xs-4 d-flex"}>
                <img width={"100%"} src={image} alt={title} />
            </div>
            <div className={"col-xs-4 d-flex align-items-center justify-content-center"}>
                <div>
                    <h5 className={"mb-2"}>{title}</h5>
                    <p>{description}</p>
                </div>
            </div>
            <div className={"col-xs-4 d-flex flex-column gap-3 align-items-center justify-content-around"}>
                <div className={"w-100"}>
                    <LinearProgressBar valueBar={percentCompleted}></LinearProgressBar>
                    <h6 className={"text-center text-lowercase mt-1"}>{daysLeft} ZILE RĂMASE</h6>
                </div>
                <div className={"d-flex justify-content-around align-items-center w-100 gap-3"}>
                    <div className={"text-center"}>
                        <p className={"fw-bold"}>PÂNĂ ACUM</p>
                        <h3 className={"fw-bold text-success"}>{moneyDonated.toLocaleString()} RON</h3>
                    </div>
                    <hr style={{ height: "100%", borderLeft: "2px solid rgb(0 0 0)" }} />
                    <div className={"text-center"}>
                        <p className={"fw-bold"}>SCOP</p>
                        <h3 className={"fw-bold"}>{moneyNeeded.toLocaleString()} RON</h3>
                    </div>
                </div>
                <Button variant="outlined" size="large" color="ochre">
                    VREAU SĂ AJUT
                </Button>
            </div>
        </div>
    );
}